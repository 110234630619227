import { isBrowser, loginSilentCallback } from "@services/auth"

const SilentCallBackPage = () => {
  console.log("Silent Callback")
  if (isBrowser) {
    loginSilentCallback()
  }
  return null
}

export default SilentCallBackPage
